import React, { useState } from "react";
import { FaRegCopy } from "react-icons/fa";

const HomePage: React.FC = () => {
  const [emailVisible, setEmailVisible] = useState(false);
  const [copyMessage, setCopyMessage] = useState(false);
  const email = "thao.vu2016@gmail.com"; // Replace with your actual email

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email);
    setCopyMessage(true); // Show the success message
    setTimeout(() => setCopyMessage(false), 3000); // Hide after 3 seconds
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      {/* Bio Section */}
      <div className="text-center max-w-2xl bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-xl font-bold text-gray-600">Welcome to My Page!</h1>
        <p className="mt-4 text-lg text-gray-600 text-justify">
          Hi, my name is Thao (pronounced like 'town' without the ending 'n').
          I'm a computer science instructor at MIU University with over 10 years
          of experience in software engineering and 3+ years teaching CS
          master's students. I'm passionate about coding, cloud computing, AI
          and empowering developers with AWS, React, React Native, Node.js, and
          computer science.
        </p>
      </div>

      <div className="mt-8 flex justify-center">
        <div className="text-center bg-red-50 p-6 rounded-lg shadow-md max-w-lg w-full">
          <h2 className="text-2xl font-semibold text-gray-800">
            Follow Me on YouTube!
          </h2>
          <p className="mt-4 text-gray-600">
            Explore my YouTube channel for hands-on tutorials and valuable tech
            content.
          </p>
          <a
            href="https://www.youtube.com/@thaovu-org" // Replace with your channel link
            target="_blank"
            rel="noopener noreferrer"
            className="mt-6 inline-block bg-red-600 text-white text-lg font-medium py-2 px-6 rounded-lg shadow hover:bg-red-700 transition duration-300"
          >
            Watch Now
          </a>
        </div>
      </div>
      <div className="text-center bg-gray-100 p-6 rounded-lg shadow-md max-w-lg w-full mt-10">
        <h2 className="text-2xl font-semibold text-gray-800">
          Check the Source Code!
        </h2>
        <p className="mt-4 text-gray-600">
          Find the source code for my YouTube AWS demos on GitHub.
        </p>
        <a
          href="https://github.com/Thao-V/youtube-aws-demo-projects"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-6 inline-block bg-gray-800 text-white text-lg font-medium py-2 px-6 rounded-lg shadow hover:bg-gray-900 transition duration-300"
        >
          View on GitHub
        </a>
      </div>
      {/* <div className="mt-12 bg-blue-50 p-6 rounded-lg shadow-md max-w-lg">
        <h2 className="text-2xl font-semibold text-gray-800">Contact Me</h2>
        <p className="mt-4 text-lg text-gray-600">
          If you have any questions or feedback, feel free to reach out!
        </p>
        <button
          onClick={() => setEmailVisible(true)}
          className="mt-4 bg-blue-600 text-white text-lg font-medium py-2 px-6 rounded-lg shadow hover:bg-blue-700 transition duration-300"
        >
          Show My Email
        </button>

        {emailVisible &&
          <div className="mt-4">
            <div className="flex items-center justify-between bg-white p-4 rounded shadow">
              <span className="text-lg text-gray-700">
                {email}
              </span>
              <button
                onClick={copyToClipboard}
                className="text-blue-600 hover:text-blue-800 transition duration-300 flex items-center"
              >
                <FaRegCopy className="inline w-5 h-5 mr-1" />
                Copy
              </button>
            </div>
            {copyMessage &&
              <p className="mt-2 text-sm text-green-600">
                Email copied to clipboard!
              </p>}
          </div>}
      </div> */}
    </div>
  );
};

export default HomePage;
